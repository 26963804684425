import store from '@/store';
import { basePost } from '@/js/services/baseService';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const { state, dispatch } = store;

export default function (params) {
    const { handleSubmit, customSwal, handleCloseModal, resetForm, orderLocationChangeNum, saveLocation } = params;
    const router = useRouter();

    const handleReservation = handleSubmit(async (values) => {
        const {
            customer_phone,
            reserve_date,
            reserve_time,
            reserve_people,
            demand,
            remark,
            name,
            customer_source,
            customer_remark,
            customer_memo_external,
            remark_tag,
            source_tag,
            memo_external_tag
        } = values;

        const newDemand = demand.map((item) => {
            const externalContent = [];
            if (item.external) {
                [...item.external].forEach((item) => {
                    if (!item.list) return;
                    item.list.forEach((val) => {
                        externalContent.push({
                            service_id: val.service_id,
                            type: 3,
                            txt: item.external_number,
                            external_id: item.external_id,
                            is_old: val.is_old,
                        });
                    });
                });
            }
            const { external, ...args } = item;
            return {
                ...args,
                service: [...(item.service || []), ...externalContent],
            };
        });

        if (orderLocationChangeNum.value > 1) {
            await saveLocation(false);
        }

        try {
            const res = await basePost('/api_reserve/reserve_check', {
                store_id: state.auth.store_id,
                customer_phone,
                reserve_date,
                reserve_time,
                reserve_people,
                demand: JSON.stringify(newDemand),
                remark,
                name,
                reserve_id: state.reservationTable.selectedEventId || '0',
                customer_source,
                customer_remark,
                customer_memo_external,
                remark_tag: JSON.stringify(remark_tag),
                source_tag: JSON.stringify(source_tag),
                memo_external_tag: JSON.stringify(memo_external_tag)
            });
            const { status, data } = res;

            if (status === 200 && data.res_code === 1) {
                const viewStatus = state.reservationTable.viewStatus;
                const selectedEventId = state.reservationTable.selectedEventId;
                const monthlySelectedMemberId = state.reservationTable.monthlySelectedMemberId;

                if (viewStatus === 'daily') {
                    await dispatch('reservationTable/getAromaStatus', {
                        store_id: state.auth.store_id,
                    });

                    await dispatch('reservationTable/getList', {
                        store_id: state.auth.store_id,
                        member_id: state.reservationTable.selectedMemberIdArr,
                        reqType: 'daily',
                    });
                } else {
                    dispatch('reservationTable/getList', {
                        store_id: state.auth.store_id,
                        member_id: monthlySelectedMemberId,
                        reqType: 'monthly',
                    });
                }

                customSwal
                    .fire({
                        title: '預約成功',
                        icon: 'success',
                    })
                    .then(() => {
                        handleCloseModal(resetForm);
                        if (isChangePage.value) {
                            router.push('/reservation');
                        }
                    });
            } else {
                customSwal.fire({
                    title: '預約失敗',
                    text: data.res_content,
                });
            }
        } catch (err) {
            console.error(err);
        }
    });

    const isChangePage = ref(false);

    return { handleReservation, isChangePage };
}
