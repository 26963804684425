<template>
    <div class="side-menu-item" :style="rootStyle">
        <div class="title-group mb-3">
            <div class="title me-3">客戶{{ index + 1 }} :</div>
        </div>
        <div class="row form item-top no-gutters">
            <div class="col-5 pe-2">
                <BaseSelect
                    v-if="aroma.list"
                    title="芳療師"
                    :name="`demand[${index}].member_id`"
                    :options="aroma.list"
                    isShowInitVal
                    :disabled="!serviceIdList.length"
                    isFilterSearch
                />
            </div>
            <div class="col-7 ps-1">
                <div class="row m-0">
                    <div class="col-6 ps-0 pe-1">
                        <BaseSelect
                            title="房間"
                            :name="`demand[${index}].room_id`"
                            :options="initOptions.roomList"
                            isShowInitVal
                            emptyOptionVal="不選擇"
                        />
                    </div>
                    <div class="col-6 ps-1 pe-0">
                        <BaseSelect
                            title="床號"
                            :name="`demand[${index}].room_bed_id`"
                            :options="bedList.list"
                            isShowInitVal
                            emptyOptionVal="不選擇"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="row form">
            <div class="row">
                <div class="col-6" style="position: relative; z-index: 1">
                    <div class="input-group phone-btn mb-3">
                        <button
                            type="button"
                            class="btn"
                            @click="
                                push({
                                    service_id: initOptions.serviceList[0].id,
                                    type: 2,
                                    txt: initOptions.serviceList[0].price,
                                    is_old: 0
                                })
                            "
                        >
                            <img
                                src="@/assets/tgd/lightbox/add-button.svg"
                                alt=""
                            />
                            <span> 新增服務 </span>
                            <p></p>
                        </button>
                    </div>
                </div>
                <div class="col-6"></div>
                <template
                    v-for="(service, idx) in fields"
                    :key="service.key"
                >
                    <div
                        class="col-5 pe-2"
                        style="position: relative"
                        :style="{ 'z-index': 99 - idx }"
                    >
                        <BaseSelect
                            :name="`demand[${index}].service[${idx}].service_id`"
                            :options="initOptions.serviceList"
                            @selectChange="changeService(idx)"
                            isFilterSearch
                        />
                    </div>
                    <div
                        class="col-7 ps-1 wrap"
                        style="position: relative"
                        :style="{ 'z-index': 99 - idx }"
                    >
                        <div class="cash-wrap">
                            <BaseSelectInput
                                selectClass="select-coupon"
                                inputClass="coupon-text"
                                :selectName="`demand[${index}].service[${idx}].type`"
                                :inputName="`demand[${index}].service[${idx}].txt`"
                                :options="initOptions.paymentList"
                                @selectChange="changeService(idx)"
                            />
                        </div>
                        <label class="d-inline-flex align-self-start align-items-center" style="height: 53px;">
                            <input
                                type="checkbox"
                                :false-value="0"
                                :true-value="1"
                                v-model="values.demand[index].service[idx].is_old"
                            />
                            <span>老點</span>
                        </label>
                        <div
                            class="cancel-img"
                            @click.prevent.stop="remove(idx)"
                        >
                            <a href="javascript:;">
                                <img src="@/assets/tgd/lightbox/del.svg" alt="" />
                            </a>
                        </div>
                    </div>
                </template>
            </div>

            <div class="row">
                <div class="col-6" style="position: relative; z-index: 1">
                    <div class="input-group phone-btn mb-3">
                        <button
                            type="button"
                            class="btn violation"
                            @click="
                                pushExternal({
                                    external_id: initOptions.externalList[0].id,
                                    type: 3,
                                    external_number: '',
                                    list: initOptions.externalList[0].services.map(item=> {
                                        return { ...item, is_old: 0 , service_id: item.id   }
                                    })
                                })
                            "
                        >
                            <img
                                src="@/assets/tgd/lightbox/add-button.svg"
                                alt=""
                            />
                            <span>  新增外部券服務  </span>
                            <p></p>
                        </button>
                    </div>
                </div>
                <div class="col-6"></div>
                <template
                    v-for="(field, idx) in fieldsExternal"
                    :key="field.key"
                >
                    <div class="col-5 pe-2 position-relative violation">
                        <BaseSelect
                            title=""
                            :options="initOptions.externalList"
                            :name="`demand[${index}].external[${idx}].external_id`"
                            @selectChange="changeExternal(idx)"
                            isFilterSearch
                        />
                    </div>
                    <div class="col-7 ps-1">
                        <BaseInput
                            title="券號"
                            placeholder="請輸入"
                            type="text"
                            class="form-control"
                            :name="`demand[${index}].external[${idx}].external_number`"
                        />
                        <div
                            class="cancel-img"
                            @click.prevent.stop="delExternal(idx)"
                        >
                            <a href="javascript:;">
                                <img
                                    src="@/assets/tgd/lightbox/del.svg"
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                    <div
                        class="col-12 d-flex align-items-start"
                        v-for="(item, i) in values.demand[index].external[idx].list"
                        :key="i"
                    >
                        <div class="external-info-wrap">
                            <BaseInput
                                type="text"
                                class="form-control border-radius-all"
                                :value="item.name"
                                :name="`demand[${index}].external[${idx}].list[${i}].name`"
                                :disabled="true"
                            />
                        </div>
                        <label class="d-inline-flex align-self-start align-items-center" style="height: 53px;">
                            <input
                                type="checkbox"
                                :false-value="0"
                                :true-value="1"
                                v-model="values.demand[index].external[idx].list[i].is_old"
                            />
                            <span>老點</span>
                        </label>
                    </div>

                </template>
            </div>

        </div>
    </div>
</template>

<script>
import { reactive, computed, watchEffect, watch } from "vue";
import { useFieldArray } from "vee-validate";
import useAromaList from "@/hooks/common/useAromaList";
import { useStore } from "vuex";

export default {
    name: "ReservationItem",
    props: {
        values: Object,
        customer: Object,
        index: Number,
        initOptions: Object,
        rootStyle: Object,
        isSend: {
            type: Boolean,
            default: false,
        },
        isEditSet: Boolean
    },
    setup(props) {
        const { index, initOptions, values } = props;
        const { state, getters } = useStore();
        const storeId = computed(() => state.auth.store_id).value;
        const { roomList } = initOptions;
        const bedList = reactive({
            list: [],
        });

        watchEffect(() => {
            if (values.demand.length && values.reserve_people) {
                if (!values.demand[index]) return;
                let roomId = values.demand[index].room_id;
                let list = null;

                for (let i = 0; i < roomList.length; i++) {
                    if (roomList[i].id === roomId) {
                        list = roomList[i].roomBedAry;
                        break;
                    }
                }

                bedList.list = list;
            }
        });

        // 更換選項時
        const changeService = (idx) => {
            if (values.demand[index].service[idx].type == 2) {
                const serviceId = values.demand[index].service[idx].service_id;

                values.demand[index].service[idx].txt =
                    initOptions.serviceList.find(
                        (item) => item.id == serviceId
                    ).price;
            }
        };

        const { push, fields, remove } = useFieldArray(
            `demand[${index}].service`
        );
        const { push: pushExternal, fields: fieldsExternal, remove: delExternal } = useFieldArray(
            `demand[${index}].external`
        );

        // 更換外券選項時
        const changeExternal = (idx) => {
            const thisExternal = values.demand[index].external[idx];
            const list = initOptions.externalList.find(item=> {
                return item.id === thisExternal.external_id
            })
            values.demand[index].external[idx].list = list.services.map(item=> {
                return { ...item, is_old: 0 , service_id: item.id   }
            });
        };

        const { mixService, getGoodAtService } = useAromaList(storeId);

        const serviceIdList = computed(()=>{
            if(!values.demand[index]) return [];
            return mixService(values.demand[index].service, values.demand[index].external)
            .map(item => Number(item.service_id))
            .filter((item, index, arr) => {
                return arr.indexOf(item) === index;
            })
        });

        const getAromaList = async (arr)  => {
            try {
                const res = await getGoodAtService(arr);
                aroma.data = res;
            } catch (err) {
                console.error(err);
            }
        }
        
        watch(serviceIdList, (serviceIdList) => {
            if(!serviceIdList.length) return;
            getAromaList(serviceIdList);
        })

        const aroma = reactive({
            originList: computed(()=>{
                return (initOptions.demand &&
                    initOptions.demand[index] &&
                    initOptions.demand[index].member_list) ||
                    props.customer.member_list;
            }),
            data: null,
            list: computed(() => {
                return aroma.data ? aroma.data : aroma.originList
            })
        });

        return {
            bedList,
            changeService,
            push,
            fields,
            remove,
            pushExternal,
            fieldsExternal,
            delExternal,
            changeExternal,
            aroma,
            serviceIdList
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/mixins";
@import "~@/css/grid";

.side-menu-item {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 45px;
    padding-right: 72px;
    border-bottom: 1px solid #eee;
    @include phone {
        padding: 20px;
        padding-top: 35px;
    }

    label {
        input[type="checkbox"] {
            display: none;
        }
        input[type="checkbox"] + span {
            display: inline-block;
            padding-left: 38px;
            line-height: 28px;
            background: url("~@/assets/tgd/lightbox/radio-none.svg") no-repeat;
            user-select: none;
        }
        input[type="checkbox"]:checked + span {
            background: url("~@/assets/tgd/lightbox/radio-checked.svg")
                no-repeat;
        }
    }

    .item-top {
        .col-5 {
            flex: 0 0 40%;
            max-width: 40%;
        }
        .col-7 {
            padding: 0;
            flex: 0 0 60%;
            max-width: 60%;
        }
    }
    .col-5 {
        flex: 0 0 35%;
        max-width: 35%;
    }
    .col-7 {
        display: flex;
        align-items: center;
        flex: 0 0 65%;
        max-width: 65%;
        position: relative;
        > .position-relative {
            width: 100%;
        }
        .cancel-img {
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translateY(calc(-50% - 5px));

            img {
                filter: contrast(100%);
                transition: all 0.3s;
                &:hover {
                    filter: contrast(130%);
                }
            }
        }
        .wrap {
            position: relative;
            @include phone {
                flex: 0 0 88% !important;
                max-width: 88% !important;
            }
            .cancel-img {
                transform: translate(0%, -50%);
            }
        }
        > .row {
            width: 100%;
        }
    }

    .cash-wrap,
    .external-info-wrap {
        width: calc(100% - 70px);
        padding-right: 10px;
        display: inline-block;
        :deep(.form-control.border-radius-all) {
            border-radius: 11px !important;
        }
    } 

    .title-group {
        display: flex;
        align-items: center;
        label {
            padding-left: 15px;
            span {
                font: normal normal bold 16px/21px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #000000;
                padding-left: 10px;
            }
        }
    }
    .title {
        color: #a4641a;
        font-size: 18px;
        font-weight: bold;
        span {
            font: normal normal bold 18px/22px Open Sans;
        }
    }
    form {
        .input-group {
            box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
            border: 1px solid #ffffff;
            border-radius: 11px;
            height: 53px;
            .input-group-text {
                background: transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                border: none;
                border-radius: 11px 0px 0px 11px;
                padding: 15px;
                position: relative;
                font-weight: bold;
                &:after {
                    content: "";
                    position: absolute;
                    border-right: 1px solid #eee;
                    top: 10px;
                    bottom: 10px;
                    right: 0;
                    z-index: 1;
                }
                .select-coupon {
                    border-radius: 11px 0px 0px 11px;
                    padding-right: 32px;
                    height: 100%;
                    color: #0f2a68;
                    background: #f9f9f9
                        url("~@/assets/tgd/lightbox/box1-arrow1.svg") no-repeat
                        90%;
                }

                .select-money {
                    border-radius: 11px 0px 0px 11px;
                    padding-right: 32px;
                    height: 100%;
                    color: #a4641a;
                    background: #f9f9f9
                        url("~@/assets/tgd/lightbox/box1-arrow2.svg") no-repeat
                        90%;
                }
            }
            .coupon-text {
                color: #0f2a68;
            }
            .money-text {
                color: #a4641a;
            }
            .form-control {
                background: transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                border: none;
                border-radius: 0px 11px 11px 0px;
                padding: 14px;
                font: normal normal 600 16px/22px Open Sans;
            }
            select {
                flex: 1;
                background: #f9f9f9
                    url("~@/assets/tgd/lightbox/select-arrow-box.svg") no-repeat
                    101%;
                border: none;
                border-radius: 11px;
                padding: 15px;
                font: normal normal 600 16px/22px Open Sans;
                appearance: none;
                filter: contrast(100%);
                transition: all 0.3s;
                &:hover {
                    filter: contrast(130%);
                }
            }
            .custom-rounded {
                border-radius: 0px 11px 11px 0px;
            }
            .btn {
                width: 100%;
                border-radius: 11px;
                padding: 15px;
                background: transparent
                    linear-gradient(
                        282deg,
                        #ebc496 0%,
                        #a4641a 36%,
                        #b57831 88%,
                        #a4641a 100%
                    );
                box-shadow: 0px 3px 6px #00000029;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #ffffff85;
            }
        }
    }
    .phone-btn {
        @include phone {
            width: 136px !important;
            height: 44px !important;
        }
        button {
            @include phone {
                width: 136px !important;
                height: 44px !important;
            }
        }
    }
    .col-md-5 {
        @include phone {
            flex: 0 0 40% !important;
            max-width: 40% !important;
            padding-right: 5px;
        }
    }
    .col-md-7 {
        @include phone {
            flex: 0 0 60% !important;
            max-width: 60% !important;
            padding-left: 5px;
        }
    }
    .btn {
        width: 100%;
        border-radius: 11px;
        padding: 15px;
        background: transparent
            linear-gradient(
                282deg,
                #a4641a 0%
                #b57831 36%,
                #a4641a 88%,
                #ebc496 100%,
            );
        box-shadow: 0px 3px 6px #00000029;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #ffffff85;
        span {
            position: relative;
            top: 2px;
        }
        &.violation {
            background: transparent
                linear-gradient(
                    282deg,
                    #481e00 0%,
                    #9c5828 36%,
                    #cf9064 88%,
                    #8f5126 100%,
                );
        }
    }
    .btn-cancel {
        height: 70px;
        border-radius: 11px;
        padding: 15px;
        box-shadow: 0px 3px 6px #00000029;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font: normal normal bold 18px/24px Microsoft JhengHei;
        letter-spacing: 0px;
        width: 100%;
        background: #a2a2a2 0% 0% no-repeat padding-box;
        justify-content: center;
        @include phone {
            height: 60px;
        }
        span {
            position: relative;
            top: 2px;
        }
        .price {
            display: flex;
            align-items: center;
            font: normal normal 600 16px/22px Open Sans;
            strong {
                font: normal normal bold 29px/39px Open Sans;
                letter-spacing: 0px;
                padding-left: 20px;
            }
        }
    }
    .btn-checkout {
        height: 70px;
        border-radius: 11px;
        padding: 15px;
        box-shadow: 0px 3px 6px #00000029;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font: normal normal bold 18px/24px Microsoft JhengHei;
        letter-spacing: 0px;
        width: 100%;
        @include phone {
            height: 60px;
        }
        span {
            position: relative;
            top: 2px;
        }
        .price {
            display: flex;
            align-items: center;
            font: normal normal 600 16px/22px Open Sans;
            strong {
                font: normal normal bold 29px/39px Open Sans;
                letter-spacing: 0px;
                padding-left: 20px;
            }
        }
        background: #d94343;
        justify-content: center;
    }
    .violation {
        :deep(.base-select) {
            .arrow-icon {
                background: transparent linear-gradient(1deg,  #CF9064 0%, #9C5828 61%, #81471D 100%) 0 0 no-repeat;
            }
        }
    }
}
</style>
