import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";
import store from "@/store";
import { basePost } from "@/js/services/baseService";
import SwalCustom from "@/hooks/common/useSwalCustom";

const { state, commit, dispatch } = store;
const swalCustom = new SwalCustom({
    title: "取消成功!",
    text: "",
    icon: "success",
    confirmButtonText: "確定",
});

export default function () {
    const { closeAddReservation } = useHandleLightboxOpen();
    const cleanForm = async (resetFormFn, isCancelReservation) => {
        const selectedEventId = state.reservationTable.selectedEventId;
        const reset = () => {
            resetFormFn({
                customer_phone: "",
                name: "",
                reserve_people: 0,
                reserve_date: "",
                reserve_time: "",
                demand: [],
                remark: "",
            });

            commit("reservationTable/update", {
                key: "selectedEventId",
                value: "",
            });
        };

        if (selectedEventId && isCancelReservation) {
            try {
                const res = await basePost("/api_reserve/reserve_cancel", {
                    store_id: state.auth.store_id,
                    reserve_id: selectedEventId,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const viewStatus = state.reservationTable.viewStatus;
                    const monthlySelectedMemberId = state.reservationTable.monthlySelectedMemberId; 
                    

                    if(viewStatus === 'daily') {
                        dispatch("reservationTable/getList", {
                            store_id: state.auth.store_id,
                            reqType: "daily",
                        });
                    }else {
                        dispatch("reservationTable/getList", {
                            store_id: state.auth.store_id,
                            member_id: monthlySelectedMemberId,
                            reqType: "monthly",
                        });
                    }

                    swalCustom.fire();
                    reset();
                }else {
                    swalCustom.fire({
                        title: data.res_content,
                        icon: "error",
                    });
                }
            } catch (err) {
                swalCustom.fire({
                    title: err.message,
                    icon: "error",
                });
                console.error(err);
            }
        } else {
            reset();
        }
    };
    const handleCloseModal = (resetFormFn, isCancelReservation) => {
        closeAddReservation();
        cleanForm(resetFormFn, isCancelReservation);
    };

    return { handleCloseModal };
}
