import { watch } from "vue";
import store from "@/store";
const { commit } = store;

export default function (params) {
    const { values, isEditSet } = params;

    watch(
        () => values.reserve_people,
        (newVal, oldVal) => {
            if (!values.demand) {
                values.demand = [];
            }

            if (newVal > oldVal && values.demand.length < newVal) {
                values.demand.push({
                    member_id: 0,
                    room_id: "",
                    room_bed_id: "",
                    service: [],
                    external: [],
                    member_list: []
                });
            } else if (newVal < oldVal) {
                values.demand.pop();
            }
        }
    );
}
