import { watch } from "vue";
import { basePost } from "@/js/services/baseService";
import store from "@/store";
const { state } = store;

export default function (params) {
    const { selectedEventId, setValues } = params;
    const updateValues = async (id) => {
        try {
            const res = await basePost("/api_reserve/reserve_details", {
                store_id: state.auth.store_id,
                reserve_id: id,
                date: state.reservationTable.selectedDate,
                time: "08:00"
            });
            const { status, data } = res;

            if (status === 200 && data.res_code === 1) {
                const {
                    customer_name,
                    customer_phone,
                    demand,
                    reserve_date,
                    remark,
                } = data.res_data.answer_list;
                const convertedDemand = demand.map((item) => {
                    const {
                        member_id,
                        room_id,
                        room_bed_id,
                        serviceAry,
                        externalAry,
                        type,
                    } = item;
                    const service = serviceAry.map((service) => {
                        const { service_id, txt, saleType, is_old } = service;
                        return {
                            service_id,
                            type: saleType,
                            txt,
                            is_old
                        };
                    });


                    const serviceObj = data.res_data.serviceAry.reduce((prev, curr) => {
                        return {...prev, [curr.id]: curr.name}
                    }, {})

                    const external = externalAry.map((external) => {
                        const { external_id, external_number, list } = external;
                        list.forEach(val=>{
                            val.name = serviceObj[val.service_id];
                        })
                        return {
                            external_id,
                            external_number,
                            list
                        };
                    });

                    return {
                        member_id,
                        room_id,
                        room_bed_id,
                        service_type: type,
                        service,
                        external
                    };
                });

                const payload = {
                    name: customer_name,
                    customer_phone,
                    reserve_date: reserve_date || moment().format('YYYY-MM-DD'),
                    reserve_time: demand[0].start_time,
                    reserve_people: demand.length,
                    remark,
                    demand: convertedDemand,
                    counter: state.auth.member_name,
                };

                setValues(payload);
            }
        } catch (err) {
            console.error(err);
        }
    };
    watch(selectedEventId, (id) => {
        if (!id) return;
        updateValues(id);
    });
}
